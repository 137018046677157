import {
    ADD_AND_RESET_FORM,
    SET_FORM_ELEMENT_VALUE,
    SET_FORM_ERRORS,
    SET_FORM_POPUP_OPEN
} from "./formBuilderActionTypes";

export interface IForm {
    id: number,
    formData: Array<{
        type: string,
        value: string | number | string[],
        multiple: boolean,
        values: Array<{
            label: string,
            value: string,
            selected: boolean,
        }>,
    }>
}

export const addAndResetForm = (form: IForm) => {
    return {
        type: ADD_AND_RESET_FORM,
        payload: form,
    };
}

export const setFormElementValue = (element: {
    id: number,
    index: number,
    fieldsForChange: Record<string, any>
}) => {
    return {
        type: SET_FORM_ELEMENT_VALUE,
        payload: element,
    };
}

export const setFormErrors = (errors: Record<string, Array<string>>) => {
    return {
        type: SET_FORM_ERRORS,
        payload: errors,
    };
}

export const setFormPopupOpen = (payload: { id?: number | null, open: boolean }) => {
    return {
        type: SET_FORM_POPUP_OPEN,
        payload,
    };
}